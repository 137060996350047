import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import Pagination from "../components/common/Pagination";
import Layout from "../components/common/Layout";
import moment from "moment";
import { GatsbyImage } from "gatsby-plugin-image";
import "../styles/pages/all-posts.scss";
import ArrowNextSmall from "../images/ArrowNextSmall";
import ArrowForward from "../images/ArrowForward";
import FiltersIcon from "../images/FiltersIcon";
import CloseIcon from "../images/CloseIcon";
import Seo from "../components/meta/Seo";

const AllPosts = ({ data, pageContext }) => {
  const { postsAll } = data;

  const [mobileFilters, setMobileFilters] = useState(false);

  const [uniqueTags, setUniqueTags] = useState([]);
  useEffect(() => {
    const tags = data.allPosts.nodes.map(post => post.tags[0]);
    const newUniqueTags = [...new Set(tags)];
    setUniqueTags(newUniqueTags);
  }, [data]);

  const [uniqueYears, setUniqueYears] = useState([]);

  useEffect(() => {
    const years = data.allPosts.nodes
      .map(post => moment(post.newfirst_publication_date).year())
      .filter(year => !isNaN(year));

    const newUniqueYears = [...new Set(years)];
    setUniqueYears(newUniqueYears);
  }, [data]);

  const [filteredPosts, setFilteredPosts] = useState([]);

  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    const filteredData = data.allPosts.nodes.filter(post => {
      // Check if selectedTag exists and filter posts accordingly
      if (selectedTag && !post.tags.includes(selectedTag)) {
        return false;
      }
      // Check if selectedYear exists and filter posts accordingly
      if (
        selectedYear &&
        moment(post.newfirst_publication_date).year() !== selectedYear
      ) {
        return false;
      }
      // Return true for other cases
      return true;
    });

    setFilteredPosts(filteredData);
  }, [data, selectedTag, selectedYear]);

  return (
    <div className="all-posts-all">
      <Seo page={postsAll} />
      <Layout>
        <div className="all-posts-container max-width">
          <div className="all-posts-filters desktop">
            <div className="topics">
              <h3>{data.postsAll.data.topic.text}</h3>
              {uniqueTags.map(tag => (
                <button
                  title={tag || "tag"}
                  key={tag}
                  onClick={() => setSelectedTag(tag)}
                >
                  {tag}
                  <ArrowNextSmall />
                </button>
              ))}
            </div>
            <div className="years">
              {uniqueYears.map(year => (
                <button
                  key={year}
                  title={year || "year"}
                  onClick={() => setSelectedYear(year)}
                >
                  {year}
                  <ArrowNextSmall />
                </button>
              ))}
            </div>
          </div>
          <div className="mobile-filters-container">
            <button title="filter" onClick={() => setMobileFilters(true)}>
              <FiltersIcon />
            </button>
          </div>

          {mobileFilters && (
            <div className="all-posts-filters mobile">
              <button
                title="hide post filters"
                className="hide-post-filters"
                onClick={() => setMobileFilters(false)}
              >
                <CloseIcon />
              </button>
              <div className="topics">
                <h3>{data.postsAll.data.topic.text}</h3>
                {uniqueTags.map(tag => (
                  <button
                    title={tag}
                    key={tag}
                    onClick={() => setSelectedTag(tag)}
                  >
                    {tag}
                    <ArrowNextSmall />
                  </button>
                ))}
              </div>
              <div className="years">
                <h3>{data.postsAll.data.year.text}</h3>
                {uniqueYears.map(year => (
                  <button
                    title={year}
                    key={year}
                    onClick={() => setSelectedYear(year)}
                  >
                    {year}
                    <ArrowNextSmall />
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="posts-list">
            {filteredPosts.map(post => (
              <Link to={post.url}>
                <div className="post-single">
                  <div className="post-image">
                    <GatsbyImage
                      style={{ borderRadius: 8 }}
                      image={post.data.post_image.gatsbyImageData}
                    />
                  </div>
                  <div className="post-details">
                    <div className="icon">
                      <ArrowForward />
                    </div>
                    <h3>
                      {moment(post.newfirst_publication_date).format(
                        "MMMM Do YYYY"
                      )}
                    </h3>
                    <h2>{post.data.post_title.text}</h2>
                    <p>{post.data.post_content.text.substring(0, 150)}...</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <Pagination
          pageInfo={data.allPosts.pageInfo}
          base={pageContext.base}
          enableArrows
          enableList
        />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query allPostsQuery($lang: String, $limit: Int!, $skip: Int!, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    allPosts: allPrismicPosts(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        url
        tags
        first_publication_date
        data {
          post_image {
            alt
            gatsbyImageData
          }
          post_title {
            text
          }
          post_content {
            text
            richText
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
    postsAll: prismicAllPosts(lang: { eq: $lang }, id: { eq: $id }) {
      url
      type
      data {
        meta_title
        meta_description
        topic {
          text
        }
        year {
          text
        }
      }
    }
  }
`;

export default AllPosts;
